body {
    font-family: 'Montserrat', sans-serif;
}

html,
body {
    margin: 0px;
    height: 100%;
}

* {
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.full-size {
    width: 100%;
    height: 100%;
}

.flex-dir-col {
    flex-direction: column;
}

.padding-sides {
    padding: 0 1rem;
}

.container-simil-bootstrap {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.btn-epic {
    position: relative;
    width: 100%;
    max-width: 260px;
    height: 52px;
    background: #fff;
    transform: translate3d(0px, 0%, 0px);
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.05em;
    transition-delay: 0.6s;
    overflow: hidden;
  }
  
  .btn-epic:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #3ad2d0;
    border-radius: 50% 50% 0 0;
    transform: translateY(100%) scaleY(0.5);
    transition: all 0.6s ease;
  }
  
  .btn-epic:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition: all 0.6s ease;
  }
  
  .btn-epic div {
    position: relative;
    top: 13px;
    width: 100%;
    height: 26px;
    text-transform: uppercase;
    overflow: hidden;
  }
  
  .btn-epic span {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    text-align: center;
    transition: transform 0.5s ease;
  }
  
  .btn-epic span:first-child {
    color: #fff;
    transform: translateY(24px);
  }
  
  .btn-epic span:last-child {
    color: #1e0f21;
    transform: translateY(0);
  }
  
  .btn-epic:hover {
    background: #3ad2d0;
    transition: background 0.2s linear;
    transition-delay: 0.6s;
  }
  
  .btn-epic:hover:after {
    border-radius: 0 0 50% 50%;
    transform: translateY(-100%) scaleY(0.5);
    transition-delay: 0;
  }
  
  .btn-epic:hover:before {
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition-delay: 0;
  }
  
  .btn-epic:hover span:first-child {
    transform: translateY(0);
  }
  
  .btn-epic:hover span:last-child {
    transform: translateY(-24px);
  }

@media (min-width: 768px) {
    .container-simil-bootstrap {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container-simil-bootstrap {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container-simil-bootstrap {
        width: 1170px;
    }
}