header {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    max-height: 15vh;
}

/* 
.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 960px;
    margin: 0 auto;
    padding: 0 20px;
} */

.logo {
    font-size: 24px;
}

.menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.menu li {
    margin-left: 20px;
}

.menu li a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s ease;
}

.menu li a:hover {
    color: #f2f2f2;
}

/* .navbar {
    height: 12vh;
} */

.border-bottom {
    border-bottom: 2px solid #4ec9e5;
}

.navbar {
    min-height: auto;
}

#navbarBasicExample .navbar-end {
    height: 100%;
    margin-left: auto;
    margin-right: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#navbarBasicExample .navbar-start {
    height: 100%;
    margin-left: auto;
    margin-right: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.navbar-brand {
    width: 100%;
    display: flex;
    justify-content: center;
}

.hero.is-fullheight {
    height: 88vh;
}

.navbar-end a {
    font-weight: 500;
    color: grey;
    display: flex;
}

.navbar-end a:hover {
    color: #4ec9e5;
}


.navbar-item img {
    max-height: initial;
    aspect-ratio: auto;
}

/* .navbar-item, .navbar-link{
    display: block;
} */

.effect {
    width: 100%;
    display: flex;
    justify-content: center;
}

/* .effect h2 .effect:nth-child(2) {
    margin-top: 50px;
} */
.effect h2 .effect .buttons {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.effect h2 .effect a:last-child {
    margin-right: 0px;
}

/* .effect:nth-child(2) {
    margin-top: 50px;
} */
.effect a:last-child {
    margin-right: 0px;
}

.effect i {
    position: relative;
    z-index: 3;
}

.effect.insta {
    background-color: #bc2a8d;
}

.effect.aeneas a {
    transition: transform 0.4s linear 0s, border-top-left-radius 0.1s linear 0s, border-top-right-radius 0.1s linear 0.1s, border-bottom-right-radius 0.1s linear 0.2s, border-bottom-left-radius 0.1s linear 0.3s;
}

.effect.aeneas a i {
    transition: transform 0.4s linear 0s;
}

.effect.aeneas a:hover {
    transform: rotate(360deg);
    border-radius: 50%;
}

.effect.aeneas a:hover i {
    transform: rotate(-360deg);
}

.insta {
    background: #4ec9e5;
    padding: 10px;
}

.insta svg {
    width: 30px;
    height: 30px;
    position: relative;
    top: 1px;
}

.buttons:last-child {
    margin-bottom: 0rem;
}





@media screen and (min-width: 1024px) {

    .navbar-item,
    .navbar-link {
        min-width: 7rem;
        text-align: center;
        justify-content: center;
    }

    #navbarBasicExample {
        display: flex;
        position: absolute;
        right: 0;
        height: 100%;
        /* align-items: center; */
        align-content: center;
        /* justify-content: center; */
    }


}

@media (max-width: 1023px) {
    .navbar {
        height: auto;
        min-height: auto;
    }

    .navbar-burger {
        height: 100%;
        position: absolute;
        right: 0;
    }

    .navbar-burger span {
        height: 2px;
    }

    .navbar-end a {
        justify-content: center;
    }

    .navbar-brand a.navbar-item {
        width: 100%;
        justify-content: center;
    }


    .navbar-menu {
        box-shadow: 0 0px 0px rgba(10, 10, 10, .1);
    }

    .buttons {
        justify-content: center;
    }
}