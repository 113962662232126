.container-blog {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px 40px;
    padding: 100px 50px;
}

.container-blog .card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 350px;
    max-width: 100%;
    height: 300px;
    background: white;
    border-radius: 20px;
    transition: 0.5s;
    box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
}

.container-blog .card:hover {
    height: 470px;
}

.container-blog .card .img-box {
    position: absolute;
    top: 20px;
    width: 300px;
    height: 220px;
    background: #333;
    border-radius: 12px;
    overflow: hidden;
    transition: 0.5s;
}

.container-blog .card:hover .img-box {
    top: -100px;
    scale: 0.75;
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
}

.container-blog .card .img-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container-blog .card .content {
    position: absolute;
    top: 252px;
    width: 100%;
    height: 35px;
    padding: 0 30px;
    text-align: center;
    overflow: hidden;
    transition: 0.5s;
}

.container-blog .card .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container-blog .card:hover .content {
    top: 130px;
    height: 320px;
}

.container-blog .card .content h2 {
    font-size: 1rem;
    font-weight: 700;
    color: var(--clr);
}

.container-blog .card .content p {
    color: #333;
    font-size: 0.9rem;
}

.container-blog .card .content a {
    display: inline-block;
    padding: 12px 25px;
    text-decoration: none;
    background: var(--clr);
    color: white;
    font-weight: 500;
    color: #51bbdb;
    z-index: 9999;
    position: relative;
}

.container-blog .card .content a:hover {
    opacity: 0.8;
}

.btn-one {
    color: #51bbdb;
    transition: all 0.3s;
    position: relative;
    text-align: center;
    cursor: pointer;
    margin: 0.4rem 0 0 0;
}

.btn-one span {
    transition: all 0.3s;
}

.btn-one::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: rgba(255, 255, 255, 0.5);
    border-bottom-color: rgba(255, 255, 255, 0.5);
    transform: scale(0.1, 1);
}

.btn-one:hover span {
    letter-spacing: 2px;
}

.btn-one:hover::before {
    opacity: 1;
    transform: scale(1, 1);
}

.btn-one::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: rgba(255, 255, 255, 0.1);
}

.btn-one:hover::after {
    opacity: 0;
    transform: scale(0.1, 1);
}


@media (max-width: 1200px) {
    .container-blog {
        gap: 80px 40px;
    }
}

@media (max-width: 480px) {
    .container-blog .card {
        width: 230px;
        border-radius: 15px;
    }

    .container-blog .card .img-box {
        width: 185px;
        border-radius: 10px;
    }

    .container-blog .card .content p {
        font-size: 0.8rem;
    }

    .container-blog .card .content a {
        font-size: 0.9rem;
    }

    .content h2 {
        font-size: 1.75em;
        margin-bottom: 0.7714em;
    }
}