.bg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url("../../assets/images/home.jpg");
    flex: 1;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.title {
    position: relative;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    margin: 0;
}

.subtitle {
    color: white;
    text-align: center;
}




@media (max-width: 575px) {
    .title {
        font-size: 2.5rem;
    }

    .subtitle {
        font-size: 1.1rem;
    }

    .btn-epic span{
        font-size: 1rem;
    }
}